var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pane_container" },
    [
      _vm._m(0),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "parameterForm_css",
          attrs: { model: _vm.parameterForm, "label-width": "160px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "监测站密码" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "水位基值" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "水位基值上限" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "水位基值下限" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "初始水量" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上报间隔时间" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "主备信道中心站地址" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "流量上限" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "流量系数" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "切除流速" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "监测站固态存储数据" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "闸门最大行程" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "控制模式" } },
            [
              _c("el-input", {
                attrs: { disabled: true },
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "闸位控制设定值" } },
            [
              _c("el-input", {
                attrs: { disabled: true },
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "流量控制设定值" } },
            [
              _c("el-input", {
                attrs: { disabled: true },
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "操作人" } },
            [
              _c("el-input", {
                attrs: { disabled: true },
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "操作方式" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "流量-水位对应关系" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.parameterForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.parameterForm, "password", $$v)
                  },
                  expression: "parameterForm.password",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "clear" }),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("设置")]
              ),
              _c("el-button", { attrs: { size: "mini" } }, [_vm._v("取消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "box_description",
        staticStyle: { "margin-bottom": "20px" },
      },
      [_c("span", { staticClass: "box_span" }, [_vm._v("设置参数")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }