<template>
  <div class="pane_container">
    <div class="box_description" style="margin-bottom: 20px;">
      <span class="box_span">设置参数</span>
    </div>
    <el-form ref="form" :model="parameterForm" label-width="160px" class="parameterForm_css">
      <!-- <el-form-item label="设备升级">
        <el-button type="success" size="mini" @click="onUpgrade">升级</el-button>
      </el-form-item> -->
      <el-form-item label="监测站密码">
        <el-input v-model="parameterForm.password"></el-input>
      </el-form-item>
      <el-form-item label="水位基值">
        <el-input v-model="parameterForm.password"></el-input>
      </el-form-item>
      <el-form-item label="水位基值上限">
        <el-input v-model="parameterForm.password"></el-input>
      </el-form-item>
      <el-form-item label="水位基值下限">
        <el-input v-model="parameterForm.password"></el-input>
      </el-form-item>
      <el-form-item label="初始水量">
        <el-input v-model="parameterForm.password"></el-input>
      </el-form-item>
      <el-form-item label="上报间隔时间">
        <el-input v-model="parameterForm.password"></el-input>
      </el-form-item>
      <el-form-item label="主备信道中心站地址">
        <el-input v-model="parameterForm.password"></el-input>
      </el-form-item>
      <el-form-item label="流量上限">
        <el-input v-model="parameterForm.password"></el-input>
      </el-form-item>
      <el-form-item label="流量系数">
        <el-input v-model="parameterForm.password"></el-input>
      </el-form-item>
      <el-form-item label="切除流速">
        <el-input v-model="parameterForm.password"></el-input>
      </el-form-item>
      <el-form-item label="监测站固态存储数据">
        <el-input v-model="parameterForm.password"></el-input>
      </el-form-item>
      <el-form-item label="闸门最大行程">
        <el-input v-model="parameterForm.password"></el-input>
      </el-form-item>
      <el-form-item label="控制模式">
        <el-input v-model="parameterForm.password" :disabled = "true"></el-input>
      </el-form-item>
      <el-form-item label="闸位控制设定值">
        <el-input v-model="parameterForm.password" :disabled = "true"></el-input>
      </el-form-item>
      <el-form-item label="流量控制设定值">
        <el-input v-model="parameterForm.password" :disabled = "true"></el-input>
      </el-form-item>
      <el-form-item label="操作人">
        <el-input v-model="parameterForm.password" :disabled = "true"></el-input>
      </el-form-item>
      <el-form-item label="操作方式">
        <el-input v-model="parameterForm.password"></el-input>
      </el-form-item>
      <el-form-item label="流量-水位对应关系">
        <el-input v-model="parameterForm.password"></el-input>
      </el-form-item>
      
      <div class="clear"></div>
      <el-form-item>
        <el-button  size="mini" type="primary" @click="onSubmit">设置</el-button>
        <el-button  size="mini">取消</el-button>
      </el-form-item>
    </el-form>
    
    
  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  
  export default {
    name: 'jcpz',//基础配置
    components: {
      
    },
    props: {
      //设备类型
      // deviceType:[String, Object, Array],
      visible:{
        default:false
      },
      //是否是详情，详情进入不可修改
      isCheckDetail:{
        default:false
      },
    },
    directives: {
      
    },
    data() {
      return {
        deviceType:1,
        parameterForm: {
          password: '',//监测站密码
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        }
      }
    },
    watch: {
      
    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      
    },
    mounted() {
      
    },
    methods: {
      //设备升级
      onUpgrade(){
        this.$message.success('升级成功');
      },
      //设置
      onSubmit(){
        this.$message.success('设置成功');
      },
    },
  }

</script>

<style lang="scss" scoped>
  .pane_container{
    background: #fff;
    padding: 10px;
    .box_description {
      .box_span{
        font-size: 14px;
        color: #1890ff;
        border-bottom: 2px solid #1890ff;
      }
    }
    
  }
</style>
